import React from "react";
import "./footer.css";
import { FaFacebookF, FaGithub } from "react-icons/fa";
import { FiInstagram, FiLinkedin, FiPhoneCall } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";

const Footer = () => {
  return (
    <footer>
      {/* <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Expeirience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        {/*  <li>
          <a href="#testimonials">Testimonials</a>
        </li> 
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul> */}

      <div className="footer__socials">
        <a
          href="https://www.facebook.com/profile.php?id=61555840534488&sk=about_contact_and_basic_info"
          target="_blank"
        >
          <FaFacebookF />
        </a>
        <a href="tel:00963969897477">
          <FiPhoneCall />
        </a>
      </div>

      <div className="footer__copyright">
        <small>&copy;2024 جميع الحقوق محفوظة لتطبيق حفلات</small>
      </div>
    </footer>
  );
};

export default Footer;
