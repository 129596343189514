import React, { useState } from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiLinkedinLine, RiMessengerLine } from "react-icons/ri";
import { BsFacebook, BsWhatsapp } from "react-icons/bs";
import { useRef } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();
  const [formBtnMsg, setFormBtnMsg] = useState("ارسل رسالة");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_wnu1fwb",
      "template_6a9qefi",
      form.current,
      "xmbP2ECU3xjpojepD"
    );
    setFormBtnMsg("تم الإرسال ، سنتواصل معكم في أسرع وقت");

    e.target.reset();
  };

  return (
    <section id="contact">
      {/* <h5>Get In Touch</h5> */}
      <h2>شكرا لتواصلكم معنا</h2>

      <div className="container contact__container">
        <div className="contact__options">
          {/* <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>symohannad[at]gmail.com</h5>
            <a href="mailto:symohannad@gmail.com" target="_blank">
              Send a message
            </a>
          </article> */}
          <article className="contact__option">
            <BsFacebook className="contact__option-icon" />
            <h4>Facebook</h4>
            <h5>تطبيق حفلات</h5>
            <a href="https://www.facebook.com/profile.php?id=61555840534488&sk=about_contact_and_basic_info">
              صفحة الفيسبوك
            </a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+963969897477</h5>
            <a
              href="https://api.whatsapp.com/send?phone=+963969897477"
              target="_blank"
            >
              راسلنا مباشرة
            </a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <form ref={form} onSubmit={sendEmail} style={{ width: "100%" }}>
            <input
              type="text"
              name="name"
              placeholder="الاسم"
              required
              onChange={() => setFormBtnMsg("Send message")}
            />
            <input
              type="email"
              name="email"
              placeholder="البريد الالكتروني"
              required
            />
            <textarea
              name="message"
              rows="7"
              placeholder="الموضوع ، يمكنكم ايضا ترك رقم الهاتف وسيقوم الفريق بالتواصل معكم ف أسرع وقت"
              required
            ></textarea>
            <button type="submit" className="btn btn-primary">
              {formBtnMsg}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
